module.exports = {
    // test svr
    // baseURL: "https://test.soujiyi.com",

    // svr
    baseURL: "https://manager.soujiyi.com",

    // test2
    // baseURL: "http://test.soujiyi.com:8002",

    // local test
    //baseURL: "http://192.168.11.33:8095",

    // local test2
    // baseURL: "http://localhost:8095",

    // local test3
    // baseURL: "http://192.168.11.66:8095",
    // baseURL: "http://192.168.0.184:8088",

    pageLimit: 10,
    maxPageLimit: 1000,
    
    recruitBaseURL: 'https://recruit.soujiyi.com',   //正式
    // recruitBaseURL: 'https://testrecruit.soujiyi.com' // 测试

}