import Vue from 'vue'

import ElementUi from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/element-var.scss'

Vue.use(ElementUi)

import App from './App.vue'
import router from './router'
router.beforeEach((to,from,next) => {
  if(to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),

}).$mount('#app')
