<template>
  <div class="left">
    <el-menu
      class="el-menu-vertical-demo"
      style="border-right:0;overflow:hidden;"
      default-active="1-1"
    >
      <el-submenu index="1">
        <template slot="title">
          <span>人才管理</span>
        </template>
        <el-menu-item-group style="overflow:hidden" >
          <el-menu-item index="1-1">简历列表</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.left{
  width: 170px;
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
}
.el-menu-item.is-active, .el-submenu .el-menu-item.is-active {
  background-color: #f5f6fd;
  color: #2e72f9;
  border-left: 4px solid #2e72f9;
}
</style>