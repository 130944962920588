<template>
  <div class="container">
    <div class="footer">
			<div class="info">
				<div class="content">
					<!-- 联系我们 -->
					<div class="left">
						<div class="phoneNumber">
							<div class="title">联系我们</div>
							<div class="number">400-168-6288</div>
						</div>
						<div class="address">
							<div class="">
								<p class="typeface">E-mail：info@soujiyi.com</p>
							</div>
							<div>
								<p class="typeface">深圳地址：深圳市南山区沙河街道深南大道9680号大冲商务中心A座1205</p>
							</div>
							<div>
								<p class="typeface">重庆地址：重庆市渝北区红锦大道90号恒大中渝广场2号7楼整层</p>
							</div>
						</div>
					</div>
					<div class="middle">
						<div class="headBox">
							<p class="title">快速链接</p>
						</div>
						<div class="linksBox">
							<div>
								<a href="https://www.soujiyi.com/ProductsStd.html">产品介绍</a>
							</div>
							<div>
								<a href="https://www.soujiyi.com/SolutionSt_jzkhhq.html">解决方案</a>
							</div>
							<div>
								<a href="https://www.soujiyi.com/CaseStd.html">客户案例</a>
							</div>
							<div>
								<a href="https://www.soujiyi.com/NewsStd.html">行业资讯</a>
							</div>
							<div>
								<a href="https://www.soujiyi.com/ServerSt_dlwzsjysoujryicom.html">服务支持</a>
							</div>
							<div>
								<a href="https://www.soujiyi.com/AboutSt_qywh.html">关于我们</a>
							</div>
						</div>
					</div>
					<div class="right">
						<div class="qrcode">
							<div>
								<img src="https://0.rc.xiniu.com/g2/M00/54/0D/CgAGe2DQOa6Aa4vBAABAh0oVznI467.jpg" alt="">
							</div>
							<div>
								<p class="typeface" style="text-align:center; margin-top:15px">关注微信公众号</p>
							</div>
						</div>
						<div class="qrcode">
							<div>
								<img src="https://0.rc.xiniu.com/g2/M00/59/F8/CgAGe2DmoZGAHw2QAAArbBGZzpg802.jpg" alt="">
							</div>
							<div>
								<p class="typeface" style="text-align:center; margin-top:15px">微信客服咨询</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="filing">
				<p>Copyright © 2020 重庆慧发网络科技有限公司&nbsp;&nbsp;&nbsp;</p>
				<a href="https://beian.miit.gov.cn/" target="_blank"><p>渝ICP备2022006320号-2&nbsp;</p></a>
				<p>&nbsp;|&nbsp;&nbsp;</p>
				<a href="https://www.soujiyi.com/sitemap.aspx" target="_blank"><p>网站地图</p></a>
			</div>
		</div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped >
.footer {
	width: 100%;
	height: 350px;
	.info{ 
		height: 245px;
		width: 100%;
		background-color: #212b4a;
		padding-top: 48px;
		.content {
			width: 1200px;
			height: 208px;
			margin: auto;
			display: flex;
			.left {
				width: 480px;
				height: 100%;
				.phoneNumber {
					padding-left: 110px;
					width: 100%;
					height: 90px;
					background-image: url(https://0.rc.xiniu.com/g2/M00/51/98/CgAGe2DFvHKAd_E3AAAUvlNsLZA881.png);
					background-position: 0px 0px;
					background-repeat: no-repeat;
					padding-top: 10px;
					// 电话号码字体样式
					.number {
						width: auto;
						color: #ffffff;
						font-size: 36px;
						line-height: 40px;
						position: relative;
						top: 0px;
						left: 0px;
						margin-top: 16px;
						font-family: Impact !important;
					}
				}
			}
			.middle{
				width: 336px;
				background-image: url('https://0.rc.xiniu.com/g2/M00/51/99/CgAGe2DFvZaAah2eAAADzDqzs8U196.png');
				background-repeat:no-repeat;
			}
			.right{
				width: 384px;
				background-image: url('https://0.rc.xiniu.com/g2/M00/51/99/CgAGe2DFvZaAah2eAAADzDqzs8U196.png');
				background-repeat:no-repeat;
				display: flex;
			}
		}
	}
	.filing{
		height: 55px;
		width: 100%;
		background-color: #0a1633;
		display: flex;
		justify-content: center;
		p{
			color: #bfd3f7;
			font-size: 14px;
		}
		a{
			text-decoration: none;
			p:hover{
				color: #e9e9e9;
			}
		}
	}
}

// 下面链接的 标题样式 title
.title {
	width: auto;
	color: #ffffff;
	font-size: 20px;
	position: relative;
	top: 0px;
	left: 0px;
}

// 下面链接的 字体样式 typeface
.typeface {
	margin:3px 0;
	left: 0px;
	color: #ffffff;
	bottom: auto;
	font-size: 14px;
	font-family: "微软雅黑","Microsoft YaHei",Helvetica,Tahoma,sans-serif;
}
// middle中的title
.headBox {
	margin:10px 0 0 88px;
}
// middle中的links
.linksBox {
	margin: 10px 0 0 88px;
	display: flex;
	flex-wrap: wrap;
	div{
		margin: 14px 45px 0 0;
	}
	a{
		font-size: 14px;
		color: #ffffff;
		text-decoration: none;
	}
	a:hover{
		color: #e9e9e9;
	}
}
// 二维码
.qrcode {
	margin-left: 60px;
}
</style>