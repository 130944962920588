<template>
  <div class="container">
    <router-view :key="$route.fullpath"></router-view>
  </div>
</template>

<script>
export default {
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
	min-height: 100px;
	background-color: #f6f6f6;
}
</style>