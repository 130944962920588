<template>
  <el-dialog
  title="身份认证"
  :visible.sync="isShow"
	:before-close="beforeClose"
	@open="onOpen"
	class="dialog-head"
  width="30%"
  center>
  <div class="content" v-loading = "loading">
		<el-form :model="verifyForm" ref="verifyForm" label-width="100px" class="demo-verifyForm" :rules="rules" >
			<el-form-item  prop="name">
				<el-input placeholder="请输入手机号/账号" v-model="verifyForm.name"  class="user"></el-input>
			</el-form-item>
			<el-form-item  prop="password" >
				<el-input placeholder="请输入密码" v-model="verifyForm.password" show-password class="password" ></el-input>
			</el-form-item>
		</el-form>
		<div class="tips">
			<div>
				<span>忘记设置密码，立即前往</span><span @click="toRegister" style="color:#2E72F9;margin-left:1px;cursor:pointer">设置</span>
			</div>
			<div class="right">
				<span>还没有账号？</span><span @click="toRegister" style="color:#2E72F9;cursor:pointer">去注册</span>
			</div>
		</div>
	</div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="clickCancel" style="margin: 0 20px;">取 消</el-button>
    <el-button type="primary" @click="toVerifyPower" style="margin: 0 20px;">确 定</el-button>
  </span>
</el-dialog>
</template>

<script>
import {verifyUser, loginVerify} from '@/api/recruitment.js'
import pubsub from 'pubsub-js'

export default {
	props: {
		visible: {
      type: Boolean,
      default: true,
    },
		// 是否有 登陆成功后的跳转路径
		readyPath: {
			type: String,
			default: '',
		}
	},
	data() {
		return {
			isShow: this.visible,
			verifyForm:{
				name: '',
				password: ''
			},
			registerUrl:'https://manager.soujiyi.com/#/login',
			loading: '',
			rules: {
				name: [
					{ required: true, message: '请输入手机号/账号', trigger: 'blur' },
				],
				password: [
					{ required: true, message: '请输入密码', trigger: 'blur'}
				]
			}
		};
	},
	created() {

	},
	watch: {
		visible (val) {
			this.isShow = val
		}
	},
	methods:{
		onOpen() {
			// console.log('openDialog')
		},
		toVerifyPower() {
			// 去验证权限
			// console.log('验证权限')
			let param = {
				account: this.verifyForm.name,
				verify: this.verifyForm.password,
				// type: 1
			}
			// verifyUser(JSON.stringify(param)).then((res) => {
			this.loading = true
			loginVerify(JSON.stringify(param)).then((res) => {
				// console.log(res.data)
				if(res.data.code == 200) {
					this.$message.success('认证成功！')
					// 取出token，用户信息 token保存在session
					let data = res.data.data
					let userInfo = {
						account: data.account,
						accountType: data.accountType,
						name: data.name,
						token: data.token,
						head: data.head,
					}
					// 发布 token 到 header
					pubsub.publish('tokenLive',{ isTokenLive:true, headImg:userInfo.head, account:userInfo.account })
					sessionStorage.setItem('RECRUIT_USERSESSION', JSON.stringify(userInfo));
					// 列表重新刷新数据
					this.$emit('getNewList')
					this.loading = false
					this.isShow = false
					if(this.readyPath!= '')
					{
						this.$emit('loginSuccess',this.readyPath)
					}
					this.$emit("update:visible", false);
				}else{
					this.loading = false
					this.$message.error(data.message);
				}
			}).catch((err) => {
				this.loading = false
				this.$message({type:'error',message: '账号或密码错误'})
			})
		},
		toRegister() {
			// 跳转注册
			window.open(this.registerUrl)
		},
		beforeClose() {
      this.$emit("update:visible", false);
		},
		// 关闭弹窗
		clickCancel() {
			this.beforeClose();
			this.isShow = false;
		},
	}
}
</script>

<style lang="scss" scoped>
/deep/.el-form-item {
	margin-bottom: 20px !important;
}
/deep/.el-form-item__content{
	margin-left: 0 !important;
}
/deep/.el-dialog__header {
	background-color: #2e72f9;
	text-align: start;
	span{
		position: relative;
		bottom: 3px;
		color: white;
		font-size: 16px;
	}
}
/deep/.el-dialog__body {
	padding: 30px 55px;
	padding-bottom: 30px;
}
/deep/.el-dialog__headerbtn, /deep/.el-dialog__close{
	color: #fff;
}

.user /deep/ .el-input__inner {
	background:url('@/assets/input/phone.png') no-repeat 10px center;
	padding: 0 0 0 36px;
}
.password /deep/ .el-input__inner {
	background:url('@/assets/input/password.png') no-repeat 10px center;
	padding: 0 0 0 36px;
}
.tips{
	display: flex;
	justify-content: space-between;
	button{
		height: 10px;
	}
}
</style>