import Vue from "vue";
import Router from "vue-router";

import layout from '@/layout/layout.vue'
import noFooter from '@/layout/noFooter.vue'

Vue.use(Router)

const routes = [
	{
		path: '/',
		component:layout,
		meta: {
			title: 'soujiyi'
		},
		redirect: '/talents',
		children: [
			{
				path: '/talents',
				name: 'talents',
				component: () => import('@/views/talents'),
				meta: {
					title:'外贸精英_外贸业务员_英文求职招聘简历_搜几亿'
				}
			},
			{
				path: '/community',
				name: 'community',
				component: () => import('@/views/community'),
				meta: {
					title:'搜几亿社区'
				}
			},
			{
				path: '/plugin',
				name: 'plugin',
				component: () => import('@/views/plugin'),
				meta: {
					title:'邮箱抓取工具_邮箱采集插件_chrome扩展程序下载_搜几亿'
				}
			},
			{
				path: '/question',
				name: 'question',
				component: () => import('@/views/question'),
				meta: {
					title: '问题反馈'
				}
			},
			
		]
	},
	{
		path: '/talentListInfo',
		name: 'talentListInfo',
		redirect: 'talentList',
		component: noFooter,
		children: [
			{
				path: '/talentList',
				name: 'talentsList',
				component: () => import('@/views/talents/talent-list'),
				
			},
			{
				path: '/talentList/resumeWrite',
				name: 'resumeWrite',
				component: () => import('@/views/talents/talent-list/resume-write')
			}
		],
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login')
	}
]

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
	return VueRouterPush.call(this,to).catch(err => err)
}

const router = new Router({
	routes: routes
})

export default router;