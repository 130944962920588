<template>
  <div class="container">
		<div>
			<HeadBar/>
		</div>
		<div>
			<AppMain/>
		</div>
		<div>
			<FooterInfo/>
		</div>
  </div>
</template>

<script>
import { HeadBar, FooterInfo, AppMain} from "./components" 
export default {
	components: {
		HeadBar,
		FooterInfo,
		AppMain
	}
}
</script>

<style scoped>
.container{
	overflow: hidden;
}
</style>