<template>
  <div class="container">
    <div class="content">
			<div class="pic">
				<img src="@/assets/soujiyi_logo.png" alt="" class="logo" @click="goToSoujiyi()" style="margin-top:50px" />
			</div>
      <div class="item">
				<a href="https://www.soujiyi.com/"><p>首页</p></a>
			</div>
			<el-dropdown @command="skipToList">
      	<div class="item" :style="navActive('talent')" @click="skipTo('/talents')"><p>人才库</p></div>
				<el-dropdown-menu slot="dropdown" style="margin-top:-2px;">
					<el-dropdown-item command="/talents" class="dropdown">
						<img src="@/assets/headerImg/talentIcon.png" alt="" style="width:18px;height:18px;margin-right:5px">
						<span>人才列表</span>
					</el-dropdown-item>
					<el-dropdown-item command="/talentList" class="dropdown">
						<img src="@/assets/headerImg/resumeIcon.png" alt="" style="width:18px;height:18px;margin-right:5px">
						<span>上传简历</span>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
      <div class="item" :style="navActive('community')" @click="skipTo('/community')" ><p>社区</p></div>
      <div class="item" :style="navActive('plugin')" @click="skipTo('/plugin')"><p>插件</p></div>
			<div class="item number">
				<img src="@/assets/headerImg/mobile.png" alt="" style="width:40px;height:40px" >
				<span class="phone">400-168-6288</span>
			</div>
			<div v-if="!isTokenLive || !userName " class="head_right">
				<button v-if="showTop" class="btn_login" @click="goToLogin()">登录</button>
      	<button v-if="showTop" class="btn_try" @click="goToLogin()">立即试用</button>
			</div>
			<!-- <div v-if="isTokenLive "  class="head_right"> -->
			<div v-else class="head_right">
				<img :src="headImg" alt="" style="width:40px;height:40px;border-radius:50%">
				<div style="margin-left:20px">{{userName}}</div>
			</div>
    </div>
		<Verify :visible.sync="powerVerifyDialogVisible" :readyPath.sync="readyPath" @upadate="closeVerifyDialog" @loginSuccess="skipToList" ></Verify>
  </div>
</template>

<script>

import { verifyToken } from "@/api/recruitment"
import Verify from '@/components/verify'
export default {
	components: {
		Verify
	},
  data() {
    return {
			powerVerifyDialogVisible: false,
			// 准备跳转路径
			readyPath: '',
			// token是否存活
			isTokenLive: true,
			// 方便 tokenLive 取消订阅 
			pub: '',
			userName: '',
			// 头像
			headImg: '',
			showTop: true,
		};
  },
	watch: {
		$route: {
			handler(to,from) {
				if(to.fullPath.indexOf('/question') != -1) {
					this.showTop = false
				}
				else {
					this.showTop = true
				}
			},
			immediate:true
		}
	},
	methods: {
		// 导航选中
		navActive(path) {
			if(this.$route.fullPath.indexOf(path) != -1) {
				return 'color:#2e72f9;border-bottom:3px solid #2e72f9;'
			}
			else {
				return 'color:#000;'
			}
		},
		// 路由跳转
		skipTo(path) {
			if(path == '/community') {
				window.open('https://bbs.soujiyi.com','_self')
				return
			}
			if(path == this.$route.name) {
				return
			}
			else {
				this.$router.push({ path:path })
			}
		},
		// 关闭身份验证dialog
		closeVerifyDialog() {
			this.powerVerifyDialogVisible = flase
		},
		// 跳转到搜几亿首页
		goToSoujiyi() {
			window.open('https://www.soujiyi.com/','_self')
		},
		// 跳转到搜几亿登陆注册
		goToLogin() {
			this.$router.push({
				path:'/login',
				query: {
					path: this.$route.path 
				}
			})
		},
		// 跳转到简历管理列表  先验证是否登陆
		async skipToList (command) {
			let token = JSON.parse(sessionStorage.getItem("RECRUIT_USERSESSION"))?.token || undefined
			this.checkToken(token,command,false)
		},
		// 校验token 鉴权逻辑 第一个参数token  第二个参数 跳转路由地址 第三个参数是 是否停留该页面
		async checkToken(token,path,stayThisPage) {
			// 如果token 存在 验证token
			if (token) {
				let res = await verifyToken(token)
				if(res.data.data == false) {
					this.$confirm( 'token已失效，是否重新认证?', '提示', {
					confirmButtonText: '前往认证',
					cancelButtonText: '取消',
					customClass: 'blue-msg',
					dangerouslyUseHTMLString: true,
					// type: 'warning'
					}).then(() => {
						this.$router.push({
							path:'/login',
							query: {
								path:path 
							}
						})
						return
					}).catch( () => {});
				}
				// 验证通过后 判断是否停留本页面
				else if(res.data.data == true){
					if(!stayThisPage){
						this.$router.push({ path:path }).catch(()=>{})
					}
					else{
						return
					}
				}
			}
			// 不存在token 登陆认证
			else {
				// pubsub.publish('isTokenLive',false)
				this.isTokenLive = !this.isTokenLive
				this.$confirm( '未认证，是否前去认证', '提示', {
        confirmButtonText: '前往认证',
        cancelButtonText: '取消',
				customClass: 'blue-msg',
        dangerouslyUseHTMLString: true,
        // type: 'warning'
				}).then(() => {
					this.$router.push({
						path:'/login',
						query: {
							path: path
						}
					})
				}).catch(() => {});
			}
		},
		// 获取tokenLive _为publish订阅名 _只是为了占位
		// getIsTokenLive(_,res) {
		// 	this.isTokenLive = res
		// 	console.log(res)
		// },
		// 登陆过后获取token和头像
		// getIsTokenLiveAndHeadImg(_,res) {
		// 	console.log(res)
		// 	this.isTokenLive = res.isTokenLive
		// 	this.headImg = res.headImg
		// 	this.userName = res.account
		// }
	},
	// 订阅
	mounted() {
		this.userName = JSON.parse(sessionStorage.getItem("RECRUIT_USERSESSION"))?.account || ''
		this.headImg = JSON.parse(sessionStorage.getItem("RECRUIT_USERSESSION"))?.head || ''
		// this.pub = PubSub.subscribe('isTokenLive',this.getIsTokenLive)
		// this.pub2 = pubsub.subscribe('tokenLive',this.getIsTokenLiveAndHeadImg)
		if(this.userName.length>0) {
			this.$nextTick(()=>{
				this.isTokenLive = true
			})
		}
		
	},
	// 取消订阅
	beforeDestroy() {
		// pubsub.unsubscribe(this.pub)
		// pubsub.unsubscribe(this.pub2)
	}
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100px;
  max-height: 100px;
  overflow: hidden;
}
.content {
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 100px;
  max-height: 100px;
	min-width: 1200px;
  width: 1200px;
	.pic {
		margin-right: auto;
		height: 100px;
	}
}
.head_right {
	height: 40px;
	width: 180px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.logo {
  position: relative;
  transform: translate(0 -50%);
  margin-right: auto;
	cursor: pointer;
}
.btn_login {
  margin-right: 20px;
  height: 40px;
	width: 70px;
	background-color: #fff;
	border-radius: 6px;
  border: 1px solid #2e72f9;
  color: #2e72f9;
  font-size: 16px;
	cursor: pointer;
}
.btn_login:hover {
  background-color: #2e72f9;
  color: white;
}
.btn_try {
  width: 110px;
  height: 40px;
  background-color: #2e72f9;
	border-radius: 6px;
  border: 1px solid #2e72f9;
	color: white;
  font-size: 16px;
	cursor: pointer;
}
.btn-try:hover {
  background-color: #2e72f9;
}
.phone {
  margin-left: 10px;
  margin-right: 40px;
  color: #2e72f9;
  font-weight: bold;
  font-size: 16px;
  font-family: "微软雅黑", "Microsoft YaHei", Helvetica, Tahoma, sans-serif;
	border-bottom: 3px solid white;
}
.item {
	height: 100px;
  margin: 0 30px;
  font-size: 18px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 3px solid white;
	box-sizing: border-box;
	a {
		text-decoration: none;
		color: #000;
	}
}
.number{
	margin-left: 60px;
}
// 下拉框
.dropdown{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
</style>