/**
 * 统一管理请求地址
 * get
 * post
 */
import $axios from 'axios'
import { recruitBaseURL } from '@/global'
// $axios.defaults.baseURL = 'http://testrecruit.soujiyi.com'
$axios.defaults.baseURL = recruitBaseURL
$axios.defaults.timeout = 8000
/**
 * 招聘列表（游客访问）
 * http://192.168.0.146:8082
 */
// const baseURL = 'http://192.168.0.66:8082'
// 验证用户
export function verifyUser(data) {
  return $axios.post(`/api/login`, data, { headers: { 'Content-Type': 'application/json' } })
}

// 验证token
export function verifyToken(data) {
  return $axios.get(`/api/login`, { headers: { 'Content-Type': 'application/json' , 'token':data } })
}

// 分页查询简历信息
export function queryList(data) {
  return $axios.get(`/api/recruiter`,  { params:data  , headers: { 'Content-Type' : 'application/json'} })
}

// 获取附件简历
export function getCurriculumVitae(id) {
  return $axios.post(`/api/recruiter/${id}`, { headers: { 'Content-Type': 'application/json' } })
}

/**
 *  管理端
 *  http://192.168.0.146:9066
 */
// const mbaseURL = 'http://192.168.0.66:9066'

// 获取附件简历
export function loginVerify(data) {
  return $axios.post(`/admin/login`, data, { headers: { 'Content-Type': 'application/json' } })
}

// 验证token
export function toVerifyToken(data) {
  return $axios.get(`/admin/login` ,  { headers: { 'Content-Type' : 'application/json', 'token':data} })
}

// 新增求职信息
export function newJobInfo(data,token) {
  return $axios.post(`/admin/recruiter`,data, { headers: { 'Content-Type' : 'application/json', 'token':token } } )
}

// 管理端获取分页求职信息
export function getAdminList(data,token) {
  return $axios.get(`/admin/recruiter`,  { params:data  , headers: { 'Content-Type' : 'application/json', 'token':token } })
}

// 管理端获取求职者详细信息
export function getDetailInfo(data,token) {
  return $axios.get(`/admin/recruiter/${data}`, { headers: { 'Content-Type' : 'application/json', 'token':token } } )
}

// 更改求职者详细信息
export function changeDetailInfo(data,token) {
  return $axios.put(`/admin/recruiter/${data.id}`, data , { headers: { 'Content-Type' : 'application/json', 'token':token } } )
}

// 删除一条求职者详细信息
export function deleteDetailInfo(data,token) {
  return $axios.delete(`/admin/recruiter/${data}`, { headers: { 'Content-Type' : 'application/json', 'token':token } } )
}

// 删除多条求职者详细信息
export function deleteDetailInfoMore(data,token) {
  return $axios.delete(`/admin/recruiter/remove/list`, { data: data, headers: { 'Content-Type' : 'application/json', 'token':token } } )
}


