<template>
  <div class="container">
		<div>
			<HeadBar/>
		</div>
    <div class="bigBox">
      <div class="box">
        <!-- <div class="leftBar">
          <LeftBar/>
        </div> -->
        <div class="appMain" ref="Main">
          <AppMain/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HeadBar, AppMain, LeftBar} from "./components" 
export default {
	components: {
		HeadBar,
		AppMain,
    LeftBar
	},
}
</script>

<style lang="scss" scoped>
.container {
	overflow-x: hidden;
}
.bigBox {
  width: 100%;
  background-color: #f6f6f6;
  min-height: calc(100vh - 100px);
}
.box {
  display: flex;
  align-items: stretch;
  width: 1200px;
  margin:auto;
  background-color: #f6f6f6;
}
.leftBar {
  margin: 20px;
  background-color: white;
}
.appMain {
  min-width: 700px;
  max-width: 1300px;
  width: 100%;
  padding: 20px 0;
}
</style>


<style lang="scss">
 /* 标签弹窗样式 nofooter下全部 */
.blue-msg{
	border: none;
	.el-message-box__header,.el-dialog__header{
		background-color: #2e72f9 !important;
		border-color: #2e72f9 !important;
		padding: 10px 30px;
	}
	.el-message-box__title,
	.el-message-box__headerbtn .el-message-box__close,
	.el-dialog__title,
	.el-dialog__headerbtn .el-dialog__close{
		color: #fff !important;
		font-size: 16px;
	}
  .el-message-box__close {
    position: relative;
    bottom: 6px;
  }
	.el-dialog__headerbtn{
		top: 12px;
	}
	.el-message-box__content{
    margin-top: 25px;
		text-align: center;
	}
	.el-checkbox.is-bordered.el-checkbox--mini{
		margin: 5px;
	}
	.el-checkbox.is-bordered.is-checked {
    background-color: #F7F8FF;
	}
	.el-checkbox.is-bordered:hover{
		background-color: #F7F8FF;
	}
  .el-message-box__btns{
    text-align: center;
    margin-bottom: 10px;
  }
  .el-button--primary {
    color: #FFFFFF;
    background-color: #2e72f9;
    border-color: #2e72f9
  }
}

</style>